<template>
  <section>
    <div class="stat-body p-1">
      <div class="mb-3" style="display: flex">
        <el-tooltip
        placement="top-start"
        :content="component.component_name">
        <p
          class="Title_sty mb-0 fw-300 pl-1"
          style="flex-grow: 1"
          v-on:click="openTableDialog()"
        >{{ name | truncate(hide_options ? 20 : 10, "...") }}</p>
      </el-tooltip>
        <div class="action_sty" v-if="!hide_options">
          <el-link
            icon="el-icon-edit"
            :underline="false"
            @click="editStat"
            style="margin-right: 5px"
          ></el-link>
          <el-link icon="el-icon-delete" :underline="false" @click="deleteStat"></el-link>
        </div>
      </div>
      <div class="d-flex align-items-center" v-if="isApplicationUser">
        <div class="circle-for-stats" :style="`background: ${iconBgColor} !important;`">
          <icons :iconName="component.icon ? component.icon : 'dashboard'" style="height: 22px; width: 22px; margin-top: 6px !important"></icons>
        </div>
      </div>
      <div class="d-flex align-items-center" v-else>
        <icons class="iconStyle" :iconName="component.icon"></icons>
      </div>
      <div></div>
      <!-- is_currency -->
      <p class="number_c_sty count fw-300" v-on:click="openTableDialog" v-if="isCurrencyStat">
        {{
        Intl.NumberFormat("en-US", {
        style: "currency",
        currency: component.currency_type ? component.currency_type : "USD",
        }).format(count)
        }}
      </p>
      <p class="number_c_sty count fs-6 fw-300" v-on:click="openTableDialog" v-else>{{ count }}</p>
    </div>
    <dialog-component
      :title="component.component_name"
      :visible="tableVisible"
      @before-close="closeDialog" 
      :containerMinWidth="showFullTable ? '90vw' : 'auto'"
      :containerHeight="showFullTable ? '100vh' : 'auto'"
      :isShowFooter="false"
    >
      <div :style="{ 'max-height': maxHeight, 'overflow-y': 'auto', 'scrollbar-width': 'thin' }">
        <CustomDashboardTable
          v-if="tableVisible"
          :component="component"
          :entity_id="entity_id"
          :filter_id="filter_id"
          :customFilters="customFilters"
          :hide_options="true"
          :isFromApplicationuser="isApplicationUser"
          @expendTable="expendTable"
          :hasViewPermission="hasViewPermission"
          :hasEditPermission="hasEditPermission"
        ></CustomDashboardTable>
      </div>
    </dialog-component>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tableVisible: false,
      showFullTable: false,
      iconBgColor: "",
      iconColor: ""
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile"
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getContactLoginSlug"
    ]),
    isCurrencyStat() {
      return (
        this.component &&
        this.component.display_type == "sum" &&
        this.component.is_currency
      );
    },
    isApplicationUser() {
      return (
        this.$route.name == "entity-custom-dashboard-config" ||
        this.$route.name == "application-user-dashboard" ||
        this.$route.name == "application-user-page-dashboard"
      );
    },
    maxHeight() {
      return this.showFullTable ? '95vh' : '70vh';
    }
  },
  props: {
    name: String,
    count: Number,
    entity_id: String,
    filter_id: String,
    component: Object,
    index: Number,
    hide_options: Boolean,
    customFilters: Array,
    isFromApplicationuser: Boolean,
    hasViewPermission: Boolean,
    hasEditPermission: Boolean,
  },
  components: {
    CustomDashboardTable: () => import("./customDashboardTable.vue")
  },
  mounted() {
    //at application users side using company slug
    if (this.getContactLoginSlug) {
      this.iconBgColor = this.getContactLoginSlug.button.background;
      this.iconColor = this.getContactLoginSlug.icon_color;
    }
    //at Owner side configuration for Application users
    if (this.getCompanyDetails) {
      this.iconBgColor = this.getCompanyDetails.button.background;
      this.iconColor = this.getCompanyDetails.icon_color;
    }
  },
  methods: {
    closeDialog(){
      this.tableVisible = false;
      this.$emit("disableDrag", false);
      if (this.showFullTable) {
        this.showFullTable = false;
      }
    },
    getParentPath(selectedCategory) {
      if (selectedCategory == "default" || !selectedCategory) {
        if (this.iconColor == "White") {
          return "menuApplicationUsers" + "White";
        } else {
          return "menuApplicationUsers";
        }
      } else {
        if (this.iconColor == "White") {
          return selectedCategory + "-" + "White";
        } else {
          return selectedCategory + "-" + "black";
        }
      }
    },
    expendTable() {
      this.showFullTable = !this.showFullTable;
    },
    editStat() {
      this.$emit("editStat", this.index);
    },
    deleteStat() {
      this.$confirm("Are you sure to delete the stat?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.$emit("deleteStat", this.index);
      });
    },
    openTableDialog() {
      if(this.component?.display_type == 'FORMULA'){
        return this.$notify({
          title: 'Info',
          message: 'we cant view the data related to formula in stats'
        })
      }
      this.$emit("disableDrag", true);
      if(this.component?.show_data_in_table){
        this.$emit("openStatDataIntable", this.component.keyValue);
      }else{
        this.tableVisible = true;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.Title_sty {
  font-size: 12px;
  margin-left: -20px;
  margin-top: -10px;
  font-weight: 700;
}
.stat-body {
  .action_sty {
    //  margin-left:-200px;
    margin-top: -10px;
    font-size: 10px;
    visibility: hidden;
  }
  &:hover {
    .action_sty {
      visibility: visible !important;
    }
  }
}
.iconStyle {
  width: 25%;
  margin-left: 100px;
  margin-top: -12px;
  @media (max-width: 399.98px) {
    width: 36px;
    margin-top: -19px;
  }
}
.number_c_sty {
  margin-top: -31px;
  margin-left: -10px;
}
.iconStyleApp {
  margin-left: 70px;
  margin-top: -12px;
}
.circle-for-stats {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  margin-left: 70px;
  margin-top: -12px;
}
</style>
